
import React from 'react'
import ClipLoader from "react-spinners/ClipLoader";
import "./Modal.sass"

const Modal= (props) => (
  <section 
    className={`modal ${props.isOpen ? 'open' : ''} ${props.loading ? 'loading' : ''}`}
    onClick={() => props.onClose()}>
      {props.loading ? (
        <ClipLoader
          color="#7DE77B"
          size={100}
          loading={props.loading}
        />
      ) : ''}

      <div 
        className="modal-container" 
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {props.children}
      </div>
  </section>
)

export default Modal;