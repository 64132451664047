
import React from 'react'
import Modal from '../../modal/Modal'
import "./DownloadBookModal.sass"

function DownloadBookModal (props) {
  const { link, format, isOpen, onClose, loading } = props;

  return (
    <Modal
      isOpen={isOpen}
      loading={loading}
      onClose={() => onClose()}
    >
      <div className="download-book-modal" style={{ textAlign: 'center' }}>
        <div>📕Your download is ready!</div>
        <br/>
        <a
          onClick={() => window.open(link)} 
          style={{ color: '#EA5E46', textDecoration: 'underline' }}
          href={link}>Click to download {format} version of solidbook
        </a>
      </div>
    </Modal>
  )
}

export default DownloadBookModal;