
import React, { useState } from 'react';
import { cookieService, downloadService } from '../../../../services';

export function useDownloads () {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isFetchingDownloadLink, setIsFetchingDownloadLink] = useState(false);
  const [downloadLink, setDownloadLink] = useState(false);
  const [format, setFormat] = useState('')

  const closeModal = () => {
    setModalOpen(false);
  }

  const openModal = () => {
    setModalOpen(true)
  }

  // pdf, epub, kindle
  const getBookVersion = async (version) => {
    openModal();
    setIsFetchingDownloadLink(true);

    const hash = cookieService.getHashFromCookie();
    const link = await downloadService.generateDownloadLink(hash, version);

    console.log(link)
    
    setDownloadLink(link);
    setIsFetchingDownloadLink(false);
    setFormat(version);
  }

  return {
    models: {
      modal: {
        isOpen: isModalOpen
      },
      downloadLink: {
        isFetchingDownloadLink,
        link: downloadLink,
        format
      },
    },
    operations: {
      closeModal,
      openModal,
      getBookVersion
    }
  }
}