import React from 'react'
import Layout from '../components/layout/components/Layout';
import Loading from '../components/shared/loading/components/Loading';
import NotAuthenticated from '../components/shared/notAuthenticated/components/NotAuthenticated';
import { useAuth } from "../services/AuthProvider"
import DownloadBookModal from '../components/shared/downloads/components/DownloadBookModal';
import { useDownloads } from '../components/shared/downloads/hocs/useDownloads';

const DownloadPage = () => {
  const { models, operations } = useDownloads();

  return (
    <div className="">
      <DownloadBookModal
        isOpen={models.modal.isOpen}
        onClose={() => operations.closeModal()}
        format={models.downloadLink.format}
        loading={models.downloadLink.isFetchingDownloadLink}
        link={models.downloadLink.link}
      />
      <h1>Downloads</h1>
      <p>You can download the most recent version of the book here!</p>

      <h2>Most recent versions</h2>
      <p>To download the PDF version (<a onClick={() => operations.getBookVersion('pdf')} href="javascript:void(0);">click here</a>).</p>
      <p>To download the EPUB version (<a onClick={() => operations.getBookVersion('epub')} href="javascript:void(0);">click here</a>).</p>
    </div>
  )
}

const Downloads = () => {
  const { isAuthenticated, isCheckingAuthState } = useAuth();
  return (
    <Layout>
      {isCheckingAuthState ? (
        <Loading/>
      ) : isAuthenticated ? (
        <DownloadPage/>
      ) : (
        <NotAuthenticated/>
      )}
    </Layout>
  )
}



export default Downloads;